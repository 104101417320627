
<template>
  <div v-if="userId != null" class="chat__header">
    <vs-navbar class="p-2 flex navbar-custom" color="white" type="flat">
      <!-- MenuIcon -->
      <div class="relative flex ml-2 mr-4">
        <feather-icon icon="ListIcon" class="cursor-pointer" v-if="isSidebarCollapsed" @click.stop="$emit('openContactsSidebar')" />
      </div>
      <!-- 標題 -->
      <vs-row>
        <feather-icon icon="UsersIcon" class="mr-4 cursor-pointer" @click="showGroup(userId.usermid)" v-show="selectedTab==2"/>
        <span class="cursor-pointer" v-if="userId!=undefined">{{userId.uname}}</span>
        <span class="ml-1 cursor-pointer" v-if="selectedTab==2 && totalGroup!=0" @click="showGroup(userId.usermid)">
          <span class="total_group">( {{totalGroup}} )</span>
        </span>
      </vs-row>
      <!-- 功能選項 -->
      <vs-dropdown vs-custom-content vs-trigger-click class="mt-1 mr-2">
        <div>
          <feather-icon icon="MoreVerticalIcon" />
        </div>
        <vs-dropdown-menu>
          <ul style="min-width: 8rem">
            <!-- 匯出Excel -->
            <li class="flex py-1 px-3 cursor-pointer hover:bg-primary hover:text-white" @click="excelExport()">
              <feather-icon class="mr-1" icon="DownloadIcon" />
              <span>{{ $t('chat.excelExport') }}</span>
            </li>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
      <!-- 顯示群組成員視窗 -->
      <vs-popup class="holamundo" :title="$t('chat.group')" :active.sync="popupGroup">
        <p v-if="allgroupname.length==0">empty!</p>
        <p v-else v-for="(item, index) in allgroupname" :key="index">{{item.name}}</p>
      </vs-popup>
    </vs-navbar>
  </div>
</template>

<script>
import { getGroupMembers, fetchExcelChatLog } from '@/api/user'

export default {
  props: {
    userId: {
      type: Object,
      required: true,
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    },
    tabId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      popupGroup: false,
      allgroupname: [],
      excelData: [],
    }
  },
  computed: {
    totalGroup() {
      return this.$store.state.totalGroup
    },
    selectedTab() {
      return this.$store.getters.chatSelectedTab
    },
  },
  methods: {
    // 顯示 群組成員 視窗
    showGroup(group){
      var _self = this
      _self.allgroupname = []
      getGroupMembers({groupid: group}).then((res) => {
        let members = res.data.item
        members.forEach(function(v){
          _self.allgroupname.push({
            name: v.name
          })
        })
        _self.popupGroup = true
      })
    },
    // 匯出Excel
    async excelExport(){
      await fetchExcelChatLog({kind: this.tabId, mid: this.userId.usermid}).then((res) => {
        let data = res.data.rs
        let temp = []
        data.forEach(function(v){
          temp.push({ name: v.name, message: v.msg_txt, dateTime: v.date_time })
        })
        this.excelData = _.cloneDeep(temp)
      }).catch((error) => { console.log(error) })
      /*
        th        => 表頭
        data      => 資料
        fileName  => 檔名
        fileType  => 檔案型別
        sheetName => sheet頁名
      */
      const th = ['姓名', '訊息', '時間']
      const filterVal = ['name', 'message', 'dateTime']
      const data = this.excelData.map(v => filterVal.map(k => v[k]))
      const [fileName, fileType, sheetName] = [this.userId.uname+'_訊息紀錄', 'xlsx', 'log']
      this.$toExcel({th, data, fileName, fileType, sheetName})
    },
  }
}

</script>

<style>
.total_group{
  text-decoration: underline; 
  color: #409EFF;
  cursor: pointer;
}
</style>

