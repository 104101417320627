<template>
    <VuePerfectScrollbar class="chat-content-scroll-area border border-solid d-theme-border-grey-light" :settings="settings" id="chatRightSideScrollbar">
        <div class="chat__log" ref="chatLog">
            <div id="component-chat-log" class="m-2" v-if="showChatData">
                <div v-for="(msg, index) in showChatData" class="msg-grp-container" :key="index">
                    <!-- If previous msg is older than current time -->
                    <template v-if="showChatData[index-1]">
                        <vs-divider v-if="!isSameDay(msg.time, showChatData[index-1].time)" class="msg-time">
                            <span>{{ toDate(msg.time) }}</span>
                        </vs-divider>
                        <div class="spacer" v-if="!hasSentPreviousMsg(showChatData[index-1].isSent, msg.isSent)"></div>
                    </template>
                    <div class="flex items-start" :class="[{'flex-row-reverse' : msg.isSent}]">
                        <!-- 已收回訊息 -->
                        <div class="mx-1 mb-3 p-2 text-center text-sm bg-grey text-white" style="border-radius: 10px;" v-if="msg.del == 1">
                            已收回訊息
                        </div>
                        <!-- 大頭貼 -->
                        <template v-if="showChatData[index-1] && !msg.isSent">
                            <template v-if="(!hasSentPreviousMsg(showChatData[index-1].isSent, msg.isSent) || !isSameDay(msg.time, showChatData[index-1].time))">
                                <vs-avatar :size="windowWidth >= 415 ? '40px' :'35px'" class=" border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="msg.isSent ? 'sm:ml-2 ml-1' : 'sm:mr-2 mr-1'"></vs-avatar>
                            </template>
                        </template>
                        <template v-if="index == 0 && !msg.isSent">
                            <vs-avatar size="40px" class="border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="msg.isSent ? 'sm:ml-2 ml-1' : 'sm:mr-2 mr-1'"></vs-avatar>
                        </template>
                        <!-- 頭貼下的空白處 -->
                        <template v-if="showChatData[index-1] && !msg.isSent">
                            <div class="mr-10 sm:mr-12" v-if="!(!hasSentPreviousMsg(showChatData[index-1].isSent, msg.isSent) || !isSameDay(msg.time, showChatData[index-1].time))"></div>
                        </template>
                        <div class="mx-1" style="text-align:start;">
                            <!-- 群組聊天室裡的名字 -->
                            <template v-if="msg.name">
                                <div class="msg break-words" style="font-size:10px;" :class="{'text-white': msg.isSent, 'border-grey-light': !msg.isSent}" v-show="!msg.isSent">{{msg.name}}</div>
                            </template>
                            <!-- 所有訊息 -->
                            <div class="shadow-md chat max-w-sm" :class="{'border border-solid border-grey-light bg-white': !msg.isSent}" v-if="msg.del == 0">
                                <div style="overflow-wrap: break-word; white-space: normal;" v-html="msg.textContent"></div>
                            </div>
                        </div>
                        <div class="mx-1 mb-3" style="line-height:1em; text-align:center; align-self: flex-end;" v-if="msg.del == 0">
                            <!-- 已讀 -->
                            <span class="stamp" v-show="msg.isSent==true && msg.isSeen==true">{{$t('chat.msgIsSeen')}}<br></span>
                            <!-- 時間 -->
                            <span class="stamp">{{msg.time | moment("HH:mm")}}</span>
                        </div>
                        <!-- 收回按鈕 -->
                        <el-tooltip class="mb-3" style="align-self: flex-end;" :content="$t('chat.msgIsRecycle')" placement="bottom" effect="light" v-show="msg.isSent==true" v-if="msg.del == 0">
                            <vs-button class="p-2" type="flat" color="dark" radius @click="confirmRecycleMessage(msg.msgid)"><i class="el-icon-delete"></i></vs-button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </VuePerfectScrollbar>
</template>

<script>
import { recycleChatData } from '@/api/user'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default{
    data(){
        return {
            delmsg:'',
            settings             : {
                maxScrollbarLength : 20,
                wheelSpeed         : 0.70,
            },
            rightSideScrollbarElement: [],
            rightsidebarpage: 1,
            showChatData: [],
            nodata: false,
        }
    },
    components: {
        VuePerfectScrollbar,
    },
    props: {
        userId: {
            type: Object,
            required: true,
        },
        tabId: {
            type: Number,
            required: true,
        },
        chatRoomChange:{
            type: Number,
            required: false,
        }
    },
    watch: {
        // 頁數改變 且大於1時 新增訊息
        async rightsidebarpage(n){
            if(n>=2){
                var tempScrollHeight = this.rightSideScrollbarElement.scrollHeight
                if(this.tabId==0){
                    await this.$store.dispatch('markSeenMessagesOfUser', {'pmid': this.userId['usermid'], 'kind': this.tabId, 'page': n})
                }else if(this.tabId==1){
                    await this.$store.dispatch('markSeenMessagesOfDoctor', {'dmid2': this.userId['usermid'], 'kind': this.tabId, 'page': n})
                }else if(this.tabId==2){
                    await this.$store.dispatch('markSeenMessagesOfGroup', {'gid': this.userId['usermid'], 'kind': this.tabId, 'page': n})
                }
                if(this.chatData.length==0){
                    this.nodata = true
                }else{
                    this.showChatData = this.chatData.concat(this.showChatData)
                    let tempData = {isPinned: true, msg: this.showChatData}
                    if(this.tabId==0){
                        this.$store.commit('SET_CHAT_DATA_OF_USER', tempData)
                    }
                }
                this.$nextTick(() => {
                    this.rightSideScrollbarElement.scrollTop = this.rightSideScrollbarElement.scrollHeight - tempScrollHeight
                })
            }
        },
        // 切換聊天室
        chatData() {
            if(this.rightsidebarpage == 1){
                this.showChatData = _.cloneDeep(this.chatData)
                this.$nextTick(() => {
                    this.rightSideScrollbarElement.scrollTop = this.rightSideScrollbarElement.scrollHeight
                })
            }
        },
        // 切換聊天室
        chatRoomChange(){
            this.rightsidebarpage = 1
            this.nodata = false
        },
        // 收到訊息
        socketChatMsg(){
            if(this.socketChatMsg){
                this.showChatData.push({
                    isSeen: false,
                    isSent: false,
                    textContent: this.socketChatMsg.textContent,
                    time: this.socketChatMsg.time
                })
            }
            this.$nextTick(() => {
                this.rightSideScrollbarElement.scrollTop = this.rightSideScrollbarElement.scrollHeight
            })
        }
    },
    mounted() {
        this.rightSideScrollbarElement = document.getElementById('chatRightSideScrollbar')
        this.rightSideScrollbarElement.addEventListener('scroll', this.rightSideScrollbarListener)
        this.$nextTick(() => {
            this.rightSideScrollbarElement.scrollTop = this.rightSideScrollbarElement.scrollHeight - this.rightSideScrollbarElement.clientHeight
        })
    },
    computed: {
        chatData() {
            if(this.tabId==0){
                return this.$store.getters.chatDataOfUser.msg;
            }else if(this.tabId==1){
                return _.sortBy(this.$store.getters.chatDataOfDoctor.msg).reverse();
            }else if(this.tabId==2){
                return _.sortBy(this.$store.getters.chatDataOfGroup.msg).reverse();
            }else{
                return []
            }
        },
        hasSentPreviousMsg() {
            return (last_sender, current_sender) => last_sender == current_sender
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        socketChatMsg() {
            return this.$store.state.socketChatMsgAndTime
        }
    },
    methods: {
        // 判斷訊息是否為今天
        isSameDay(time_to, time_from) {
            return  this.$moment(time_to).format("YYYY") === this.$moment(time_from).format("YYYY") &&
                    this.$moment(time_to).format("MM") === this.$moment(time_from).format("MM") &&
                    this.$moment(time_to).format("DD") === this.$moment(time_from).format("DD");
        },
        // 顯示日期文字
        toDate(time) {
            var m = this.$moment(time).locale('en');
            const monthName = m.localeData().monthsShort(m);
            const DD = this.$moment(time).format("DD");
            return DD + ' '  + monthName;
        },
        // 收回訊息的確認視窗
        confirmRecycleMessage(msgid){
            var _self = this
            _self.delmsg = msgid
            _self.$vs.dialog({
                type:'confirm',
                color: 'danger',
                title: _self.$t('popup.alert'),
                text: _self.$t('chat.alertRecycleMsg'),
                accept:_self.recycleMessage
            })
        },
        // 收回訊息
        recycleMessage(){
            var _self = this
            _self.$vs.loading()
            recycleChatData({'msgid': _self.delmsg}).then(res => {
                if(res.data.status=='OK'){
                    let index = _.findIndex(this.showChatData, ['msgid', this.delmsg])
                    this.showChatData[index].del = 1
                }else{
                    _self.notify('danger', _self.$t('message.update_error'), '')
                }
                _self.$vs.loading.close()
            })
        },
        // 收回訊息後 刷新訊息
        async apdateChatRoom() {
            var _self = this
            if(_self.tabId==0){
                _.remove(_self.showChatData, ['msgid', _self.delmsg])
                _self.showChatData = _.cloneDeep(_self.showChatData)
            }
            else if(_self.tabId==1){
                _.remove(_self.showChatData, ['msgid', _self.delmsg])
                _self.showChatData = _.cloneDeep(_self.showChatData)
            }
            else if(_self.tabId==2){
                _.remove(_self.showChatData, ['msgid', _self.delmsg])
                _self.showChatData = _.cloneDeep(_self.showChatData)
            }
        },
        //右半邊進行滑動觸發
        rightSideScrollbarListener() {
            var rightSideScrollTop = this.rightSideScrollbarElement.scrollTop
            var rightSideScrollHeight = this.rightSideScrollbarElement.scrollHeight
            var bottomOfrightSideScrollbarElement = rightSideScrollHeight - rightSideScrollTop
            if (bottomOfrightSideScrollbarElement == rightSideScrollHeight && this.nodata == false) {
                this.rightsidebarpage++
            }
        },
        // 視窗通知
        notify(color, title, text){
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position:'top-center'
            })
        },
    },
}
</script>
